<template>
  <div id="user-list">
    <template>
      <v-card>
        <v-tabs
          color="deep-purple accent-4"
          right
        >
          <v-tab>Manuscripts</v-tab>
          <v-tab>Publication</v-tab>
          <v-tab>Affair</v-tab>
          <v-tab>Address</v-tab>
          <v-tab>ISBN</v-tab>
          <v-tab>Communication</v-tab>
          <v-tab>Commandes Lab</v-tab>
          <v-tab>Commandes Bod</v-tab>

          <v-tab-item>
            <Manuscripts />
          </v-tab-item>
          <v-tab-item>
            <Publications />
          </v-tab-item>
          <v-tab-item>
            <affair />
          </v-tab-item>
          <v-tab-item>
            <Addresses />
          </v-tab-item>
          <v-tab-item>
            <Isbns />
          </v-tab-item>
          <v-tab-item>
            <Communication />
          </v-tab-item>
          <v-tab-item>
            <Orders  url="/importOrderLab" />
          </v-tab-item>
          <v-tab-item>
            <Orders url="/importOrderBod"/>
          </v-tab-item>
        </v-tabs>
      </v-card>
    </template>
    <!-- configuration card -->

    <v-snackbar
      v-model="isSnackbarBottomVisible"
      :color="snackBarColor"
    >
      {{ snackBarMessage }}
    </v-snackbar>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiSquareEditOutline,
  mdiDotsVertical,
  mdiPlus,
  mdiClose,
  mdiFileDocumentOutline,
  mdiDeleteOutline,
  mdiExportVariant,
  mdiAccountOutline,
  mdiPencilOutline,
  mdiContentSaveOutline,
  mdiImage,
} from '@mdi/js'
import {
  computed, ref, watch, onUnmounted,
} from '@vue/composition-api'
import axios from '@axios'

// sidebar
import { avatarText } from '@core/utils/filter'
import store from '@/store'
import userStoreModule from './itemStoreModule'

import controller from './Controller'
import Affair from '@/views/apps/settings/Affair'
import Manuscripts from '@/views/apps/settings/Manuscripts'
import Publications from '@/views/apps/settings/Publications'
import Addresses from '@/views/apps/settings/Addresses'
import Isbns from '@/views/apps/settings/Isbns'
import Communication from "@/views/apps/settings/Communication";
import Orders from "@/views/apps/settings/Orders";

export default {
  components: {
    Orders,
    Communication,
    Isbns,
    Addresses,
    Publications,
    Manuscripts,
    Affair,
  },
  setup() {
    const APP_STORE_MODULE_NAME = 'configuration'
    const importing = ref(false)
    const running = ref(false)
    const counter = ref(0)
    const perPage = ref(2000)
    const n = ref(0)
    const datas = ref([])
    const total = 4674

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })
    store.state.app.settings.manuscript_approuved_status = parseInt(store.state.app.settings.manuscript_approuved_status, 10) || null
    store.state.app.settings.publication_signed_status = parseInt(store.state.app.settings.publication_signed_status, 10) || null
    store.state.app.settings.publication_bat_status = parseInt(store.state.app.settings.publication_bat_status, 10) || null
    store.state.app.settings.publication_bod_status = parseInt(store.state.app.settings.publication_bod_status, 10) || null
    store.state.app.settings.white_color_id = parseInt(store.state.app.settings.white_color_id, 10) || null
    store.state.app.settings.green_color_id = parseInt(store.state.app.settings.green_color_id, 10) || null
    const {
      item,
      items,
      tableColumns,
      searchQuery,
      totalItems,
      loading,
      options,
      configurations,
      selectedRows,
      isFormActive,
      snackBarMessage,
      snackBarColor,
      isSnackbarBottomVisible,
      openDialog,
      deleteItem,
      onSubmit,
      onSubmitMultiple,
      loadingManuscript,
      loadingColor,
      loadingQuotation,
      onSubmitMultipleColors,
      onSubmitMultipleManuscript,
      onSubmitMultipleQuotation,
      confirmDelete,
      deleteDialog,
      fetchItems,
    } = controller()

    const green = ref(null)
    const orange = ref(null)
    const red = ref(null)
    const yellow = ref(null)
    const black = ref(null)
    const white = ref(null)
    const cyan = ref(null)

    const importData = () => {
      if (counter.value <= total && running.value) {
        n.value += 1
        axios.post('/importAfaire11', {
          green: green.value,
          orange: orange.value,
          red: red.value,
          yellow: yellow.value,
          black: black.value,
          white: white.value,
          cyan: cyan.value,
          number_per_page: perPage.value,
          start_rows: counter.value,
        }).then(response => {
          counter.value = parseInt(counter.value) + parseInt(perPage.value)
          for (let i = 0; i < response.data.data.length; i++) {
            datas.value.push(response.data.data[i])
          }
          importData()
        }).catch(() => {
          // importing.value = false
        })
      } else {
        importing.value = false
      }
    }
    const startImportation = () => {
      running.value = true
      importing.value = true
      importData()
    }
    const stopImportation = () => {
      running.value = false
    }

    return {
      n,
      green,
      orange,
      red,
      yellow,
      black,
      white,
      cyan,
      startImportation,
      importing,
      counter,
      running,
      perPage,
      item,
      total,
      stopImportation,
      datas,
      tableColumns,
      searchQuery,
      totalItems,
      loading,
      options,
      configurations,
      mdiContentSaveOutline,
      isFormActive,
      selectedRows,
      snackBarMessage,
      snackBarColor,
      isSnackbarBottomVisible,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      deleteDialog,
      confirmDelete,
      openDialog,
      deleteItem,
      avatarText,
      fetchItems,
      onSubmit,
      onSubmitMultiple,
      loadingManuscript,
      loadingQuotation,
      loadingColor,
      onSubmitMultipleColors,
      onSubmitMultipleManuscript,
      onSubmitMultipleQuotation,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiClose,
        mdiExportVariant,
        mdiAccountOutline,
        mdiPencilOutline,
        mdiContentSaveOutline,
        mdiImage,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
